import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { logout } from '../../../features/auth/authSlice';
import { logEventToBackEnd, updateGlobalLoaderState } from '../../../features/global/globalSlice';
import { Nav } from '../Nav';
import { advisorConsoleActions } from '../../../features/advisorConsole';
import { initializeGoals } from '../../../features/client-goals';
import { initializeAdviceClient } from '../../../features/client';
import { resetAdviceAdminReducer } from '../../../features/adviceAdmin';
import { AlertDialog } from '../AlertDialog/AlertDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface HeaderProps {
    eulaVariant?: boolean;
    showHeaderWithoutOptions?: boolean;
}

export const Header = ({ showHeaderWithoutOptions }: HeaderProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { authState, oktaAuth } = useOktaAuth();
    const [popupOpen, setPopupOpen] = useState(false);
    const isAuthenticated = authState?.isAuthenticated;
    const isAuthLoading = useAppSelector((state) => state.auth.loading);
    const { user } = useAppSelector((state) => state.auth);

    // Reset all reducer to their initial states
    const clearAllStateData = async () => {
        dispatch(advisorConsoleActions.resetAdviceConsoleReducer());
        dispatch(initializeGoals());
        dispatch(resetAdviceAdminReducer());
        dispatch(initializeAdviceClient());
    };

    const handleLogout = async () => {
        updateGlobalLoaderState(true);
        setPopupOpen(false);
        const sessionExists = await oktaAuth.session.exists();
        if (sessionExists) {
            await oktaAuth.clearStorage();
            await oktaAuth.closeSession();
            await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
            dispatch(logout());
            clearAllStateData();  
        }
        
    };

    const handleAdminPortalNavigate = () => {
        navigate('/admin');
    };

    if (!(isAuthenticated && !isAuthLoading) && !showHeaderWithoutOptions) {
        return null;
    }

    const navItems = [
        {
            label: 'CONSOLE',
            to: '/clients'
        },
        {
            label: 'RESOURCES',
            to: '/resources',
            items: [
                {
                    label: 'TUTORIALS',
                    to: '/resources/tutorials'
                },
                {
                    label: 'ARTICLES',
                    to: '/resources/articles'
                },
                {
                    label: 'FAQ',
                    to: '/resources/faq'
                }
            ]
        }
    ];
    const handleDismiss = () => {
        setPopupOpen(false);
    };
    return (
        <>
            <Nav
                items={navItems}
                user={user}
                onClickLogout={() => setPopupOpen(true)}
                onClickAdmin={handleAdminPortalNavigate}
            />
            <AlertDialog
                type="confirmation"
                open={popupOpen}
                icon="logout"
                handleClose={handleDismiss}
                content={`${t('LOGOUT_MODAL_CONTENT')}`}
                title={t('TEXT_MENU_LOGOUT')}
                aria-label="log-out-confirmation-modal"
                data-testid="log-out-confirmation-modal"
                cancelButtonLabel={t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}
                confirmButtonLabel={t('TEXT_MENU_LOGOUT')}
                onCancellation={handleDismiss}
                onConfirmation={handleLogout}
            />
        </>
    );
};
export default Header;
